import axios from "axios"
import util from "./util"

export const getOrganization = async id => {
  return new Promise(function(resolve, reject) {
    axios.get("/organizations/" + id).then(
      response => {
        console.log("organizations queried successfully", response)
        // resolve(util.fixUrls(response.data))
        resolve(response.data)
      },
      error => {
        console.log("getOrganizations ERROR: organizations not found", error)
        reject(error)
      }
    )
  })
}

export const findByCode = async signUpCode => {
  return new Promise(function(resolve, reject) {
    axios.get("/organizations/findByCode/" + signUpCode).then(
      response => {
        console.log("organization queried successfully", response)
        resolve(response.data)
      },
      error => {
        console.log("findByCode ERROR: organization not found", error)
        reject(error)
      }
    )
  })
}

export const getOrganizations = async => {
  return new Promise(function(resolve, reject) {
    axios.get("/organizations").then(
      response => {
        console.log("organizations queried successfully", response)
        // resolve(util.fixUrls(response.data))
        resolve(response.data)
      },
      error => {
        console.log("getOrganizations ERROR: organizations not found", error)
        reject(error)
      }
    )
  })
}

export const createOrganizations = async organizations => {
  return new Promise(function(resolve, reject) {
    axios.post("/organizations", organizations).then(
      response => {
        console.log("Organization created successfully", response)

        var updatedOrganizations = response.data
        updatedOrganizations.canEdit = true
        updatedOrganizations.canDelete = true

        resolve(util.fixUrls(updatedOrganizations))
      },
      error => {
        console.log("createOrganizations error:", error)
        reject(error)
      }
    )
  })
}

export const updateOrganizations = async (id, organizations) => {
  return new Promise(function(resolve, reject) {
        axios.put("/organizations/" + id, organizations).then(
      response => {
        console.log("Organizations " + id + " updated successfully", response)
        // response.data is the updated story object
        resolve(util.fixUrls(response.data))
      },
      error => {
        console.log("updateOrganizations error:", error)
        reject(error)
      }
    )
  })
}

export const deleteOrganizations = async organizations => {
  var id = _.isString(organizations)
    ? organizations
    : organizations._id
    ? organizations._id
    : organizations.id
  return new Promise(function(resolve, reject) {
    axios.delete("/organizations/" + id).then(
      response => {
        console.log(
          "Organizations " + organizations._id + " deleted successfully",
          response
        )
        // pass back the deleted story?
        resolve(response)
      },
      error => {
        console.log("deleteOrganizations error:", error)
        reject(error)
      }
    )
  })
}

export default {
  getOrganizations,
  findByCode,
  createOrganizations,
  updateOrganizations,
  deleteOrganizations,
}
